@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .text-styles {
    p {
      @apply text-gray mb-5 text-base;
    }

    a {
      @apply text-green underline;
    }

    h1 {
      @apply text-gray-dark mb-3 text-4xl leading-10 tracking-tight font-light;
    }

    h2 {
      @apply text-gray-dark mb-5 text-2xl leading-10 tracking-tight font-light;
    }

    h3 {
      @apply text-gray-dark mb-1 text-2xl tracking-tight font-light;
    }

    h4 {
      @apply text-gray-dark mb-5 text-lg leading-6;
    }

    h5 {
      @apply text-gray-light mb-4 text-base leading-5;
    }
  }
}

@layer components {
  .bg-green-gradient {
    background-image: linear-gradient(to right bottom, #6de354 0%, #58c12c 33.33%, #43852e 100%);
  }

  .bg-gray-gradient {
    background: linear-gradient(139.13deg, #dee1e4 5.42%, #c3c7cd 34.78%, #a2a6ab 76.8%), #ffffff;
  }

  .bg-red-gradient {
    background: linear-gradient(139.13deg, #ffa8b7 5.42%, #ff617b 34.78%, #e2556c 76.8%), #ffffff;
  }

  .btn {
    @apply px-5 pt-3 pb-2 rounded transition-colors duration-300 disabled:pointer-events-none disabled:opacity-50;
  }

  .btn-medium {
    @apply px-8 py-3;
  }

  .btn-large {
    @apply pt-3 px-12 rounded-full focus:ring-4 focus:ring-green;
  }

  .btn-green {
    @apply bg-green text-gray-offWhite border border-green;
  }

  .btn-green-outline {
    @apply text-green border border-green hover:border-green-darker hover:text-green-darker;
  }

  .btn-green-gradient {
    @apply bg-green-gradient text-gray-offWhite;
  }
}
